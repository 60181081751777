@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.Wrapper {
  width: 100%;
  height: 100vh;
  background: rgb(194 217 240 / 12%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h1 {
    @include FontRMedium(9rem, grey, 10rem);
    font-style: italic;
  }

  > h2 {
    @include FontRegular(5rem, 6rem);
    color: grey;
    font-weight: 800;
  }

  > a {
    color: $checkedColor;
    text-decoration: underline;
  }
}