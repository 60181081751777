@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


header {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07) inset;
  padding: 16px 24px;
  position: fixed;
  top: 0;
  z-index: 999;

  .Container {
    width: 100%;
    @include FLexRow;
    justify-content: center;
    @media screen and (max-width: $MaxWidthL) {
      justify-content: space-between;
    }

    > div {
      @include FLexRow;
      justify-content: flex-start;

      &:first-child {
        .Title {
          @include FontRMedium(21px, rgba(255, 255, 255, 1), 25px);
          margin-left: 5px;
        }
      }
    }

    .NavDesk {
      @media screen and (max-width: $MaxWidthL) {
        display: none;
      }
      @include FLexRow;
      column-gap: 30px;

      > a {
        @include FontRegular(16px, 28px);

        &:hover {
          @include FontRMedium(16px, $checkedColor, 28px);

        }
      }
      .InActiveLink {
        @include FontRegular(16px, 28px);
        color: $defaultTableFontColor;
      }

      .ActiveLink {
        @include FontRMedium(16px, $checkedColor, 28px);
      }
    }


    .BalanceBlock {
      @include FLexRow;

      .BalanceBlock {
        margin: 0 30px 0 16px;
        @include FLexCol;

        .Balance {
          @include FontRMedium(18px, $defaultTableFontColor, 18px);

          + p {
            @include FontInterRegular(13px, $fontColorLight, 17px);
          }
        }
      }
    }

    .LogoBlock {
      display: none;

      > p {
        @include FontInterRegular(16px, $checkedColor, 24px);
        font-weight: 700;
      }

      @media screen and (max-width: $MaxWidthL) {
        @include FLexRow;

      }
    }


    .BurgerBtn {
      min-width: 30px !important;
      height: 35px !important;

      display: none;

      > svg {
        fill: $checkedColor;
      }

      @media screen and (max-width: $MaxWidthL) {
        display: block;
      }
    }

  }

  .MenuMobile {
    display: none;
    @media screen and (max-width: $MaxWidthL) {
      display: block;
    }

    > div {
      > div {
        &:first-child {
          width: 100%;
          left: 0;
          top: 73px;
        }

        &:nth-child(3) {
          top:70px;
          @media screen and (max-width: $MaxWidthS) {
            width: 100% !important;
          }
        }

        &:nth-child(2) {
          > div {
            > button {
              width: 40px !important;
              height: 35px !important;
              left: auto !important;
              right: 24px !important;
              top: 20px !important;
              //border: 2px dashed red !important;
            }
          }
        }
      }
    }

    .BurgerMenu {
      nav {
        background: #ffffff;
        @include FLexCol;
        align-items: flex-end;
        padding-right: 25px;

        > a {
          @include FontRegular(16px, 28px);
          text-transform: capitalize;
          padding: 5px 20px;

          &:hover {
            @include FontRMedium(16px, $checkedColor, 28px);

          }
        }

        .InActiveLink {
          @include FontRegular(16px, 28px);
          color: $defaultTableFontColor;
        }

        .ActiveLink {
          font-weight: 700;
          @include FontRMedium(16px, $checkedColor, 28px);
        }
      }
    }
  }
}
