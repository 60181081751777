@font-face {
    font-family: 'Roboto-Regular';
    src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url("../fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: 'Inter-Regular';
    src: url("../fonts/Inter-Regular.ttf");
}

*,
*::after,
*:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

ul, li, h1, h2, h3, h4, p {
    font-family: "Roboto-Regular";
    list-style: none;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 1);
    text-align: center;

}

ul[class],
ol[class] {
    padding: 0;
}

button {
    background: none;
    text-align: center;
    border: none;
    outline: none;
    padding: 0 0;
    cursor: pointer;
}

body {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    text-align: center;
    font-family: "Roboto-Regular";
    -ms-overflow-style: none;
    /*overflow: hidden;*/
}

/*body::-webkit-scrollbar {*/
/*    width: 0;*/
/*    display: none; !*chrome*!*/
/*}*/

img {
    max-width: 100%;
    display: block;
}

input,
button,
textarea,
select {
    font: inherit;
    outline: none;
    border: none;
}

button {
    cursor: pointer;
}

input::placeholder {
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(50, 50, 50, 0.3);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}