@import "../../assets/styles/mixines";
@import "../../assets/styles/variables";


.Wrapper {
  background: rgba(255, 255, 255, 1);
  width: 100%;
  min-height: 100vh;
  @include FLexCol;
  justify-content: center;


  .Form {
    width: 100%;
    max-width: 450px;
    @include FLexCol;
    gap: 16px;

    h1 {
      @include FontRMedium(33px, $fontColorDark, 43px);
    }

    p {
      @include FontRegular(16px, 28px);
      color: $fontColorLight
    }

    .ErrorDesc {
      color: $attentionColor;
    }

    .BtnSubmit {
      width: 100%;
      max-width: 190px;
      padding: 8px 24px 8px 24px;
      border-radius: 4px;
      @include FontRegular(16px, 28px);
      @include FLexCol;
      align-items: center;
      color: white;
      background: #0064EB;
      @media screen and (max-width: $MaxWidthS) {
        font-size: 15px;
        padding: 5px 15px;
        max-width: 150px;
      }
    }

    .BtnSubmitDisabled {
      opacity: 0.2;
    }

    .FieldWr {
      position: relative;
      width: 100%;
      @include FLexCol;
      align-items: flex-start;
      gap: 10px;
      padding: 10px 20px;

      > label {
        @include FontRegular(16px, 28px);
      }

      > .Input {
        width: 100%;

        > div {
          width: 100%;

          > input {
            border-radius: 4px;
            width: 100%;
            padding: 8px 16px;
            border: 1px solid rgba(58, 87, 232, 1);
            @include FontInterRegular(16px, rgba(0, 0, 0, 1), 28px);
            font-weight: 400;
          }
        }
      }

      .InputError {
        > div {
          width: 100%;

          > input {
            border: 1px solid red;
          }
        }
      }
    }

    .CheckBoxWr {
      width: 100%;
      @include FLexRow;

      .RememberCheckBox {
        margin-right: 8px;
        box-shadow: 0px 4px 8px 0px rgba(0, 16, 41, 0.1);
      }
    }


    .FormControlLabel {
      @include FontRegular(16px, 28px);
      color: $fontColorLight
    }
  }
}

.Error {
  position: absolute;
  left: 20px;
  bottom: -10px;
  @include FontRegular(14px, 18px);
  color: rgba(200, 0, 0, 1);
  @media screen and (max-width: $MaxWidthS) {
    font-size: 12px;
  }
}