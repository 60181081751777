@import './variables';

@mixin break($width) {
  @media screen and (max-width: $width) {
    @content
  }
}

@mixin Wrapper {
  width: 100%;
  height: 100%;
}

@mixin FLexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@mixin FLexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


@mixin FontRegular($fontSize, $lineHeight) {
  font-family: 'Roboto-Regular';
  font-size: $fontSize;
  font-weight: 400;
  line-height: $lineHeight;
  text-align: left;
  color: $fontColorDark;
  transition: all 0.1s ease-in;
}

@mixin FontRMedium($fontSize, $color, $lineHeight) {
  font-family: 'Roboto-Medium';
  font-size: $fontSize;
  font-weight: 700;
  line-height: $lineHeight;
  text-align: left;
  color: $color;
  transition: all 0.1s ease-in;
}

@mixin FontInterRegular($fontSize, $color, $lineHeight) {
  font-family: 'Inter-Regular';
  font-size: $fontSize;
  font-weight: 500;
  line-height: $lineHeight;
  text-align: left;
  color: $color;
  transition: all 0.1s ease-in;
}

@mixin BtnFilter {
  background: rgba(255, 255, 255, 0.7);
  padding: 8px 16px;
  border-radius: 4px;
  @include FontRegular(14px, 24px);
  font-weight: 700;
  color: rgba(58, 87, 232, 1);
  @include FLexCol;
  justify-content: center;

}

@mixin CommonTable {
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-top: 40px !important;

  .TableCellHead {
    border-bottom: 2px solid $defaultFontColor;
    padding: 6px 0 16px 10px;
    @include FotSemiBold(18px, $defaultFontColor, 18px);
  }

  .TableRow {
    &:nth-child(2n) {
      background-color: rgba(187, 187, 187, 0.15);
    }
  }

  .TableCell {
    @include FontRegular(14px, rgba(50, 50, 50, 0.8), 14px);
    border-bottom: 1px solid rgba(187, 187, 187, 1);
    padding: 6px 10px;
    position: relative;

    .Arrow {
      cursor: pointer;
      margin-left: 5px;

      path {
        fill: rgba(50, 50, 50, 0.8);
      }
    }
  }
}

@mixin ScrollBar {
  &::-webkit-scrollbar {
    margin-left: 10px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: rgb(249 249 249);
    box-shadow: 0px 2px 3px inset rgba(33, 33, 33, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px rgba(101, 100, 100, 0.11);
    border: 2px solid rgba(159, 159, 159, 0.2);
  }

}

@mixin ChangeAllTables {
  width: 100%;
  @include FLexCol;
  margin: 24px 0 0;

  .FormGroup {
    width: 100%;
    max-width: 375px;
    @include FLexCol;
    gap: 1px;
    border-radius: 4px;
    border: 2px solid rgba(138, 146, 166, 0.2);

    .FormControlLabel {
      width: 100%;
      margin: 0 0 !important;
      background: rgba(255, 255, 255, 1);
      padding: 0px 16px 0px 48px;
      position: relative;
      display: flex;
      align-items: center;

      > span {
        &:first-child {
          margin-right: 8px;
        }

        @include FontRegular(14px, 24px);
        color: #212529;
        padding: 8px 0 !important;
      }

      > span {
        &:first-child {
          color: $checkedColor;
        }

      }

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
      }

      &::before {
        content: url("../../assets/images/CheckBox.svg");
        position: absolute;
        width: 15px;
        height: 24px;
        left: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }

    }

  }
  .Btns {
    display: flex;
    column-gap: 16px;
    width: 100%;
    margin-top: 24px;

    .CancelBtn {
      @include CancelBtn
    }

    .ApplyBtn {
      @include FLexCol;
      justify-content: center;
      @include FontRegular(16px, 28px);
      color: white;
      width: 100%;
      background: rgba(39, 117, 202, 1);
      padding: 4px 16px 4px 16px;
      border-radius: 4px;
      height: 31px;

    }
  }
}

@mixin CancelBtn {
  @include FLexCol;
  justify-content: center;
  @include FontRegular(16px, 28px);
  color: rgba(0, 0, 0, 1);
  width: 100%;
  background: rgba(138, 146, 166, 0.2);
  padding: 4px 16px 4px 16px;
  border-radius: 4px;
  height: 31px;
}


@mixin ChangeTableBlock {
  @include ChangeAllTables;
  max-width: 440px;
  background: white;
  padding: 32px;
  border-radius: 12px !important;
  border: none !important;
  outline: none !important;

  > div {
    &:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      > h4 {
        font-family: "Roboto-Medium";
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 0, 0, 1);
      }

      > svg {
        cursor: pointer;
      }
    }
  }
}

@mixin TextField {
  > div {
    outline: none;
    border: 1px solid #eee;
    box-shadow: 0px 0px 4px 0px #f5eded4a;

    > input {
      background: #FFFFFF;
      padding: 4px 16px 4px 16px;
      border-radius: 4px;
      height: 27px;
      width: 100% !important;
      @include FontRegular(16px, 26px);
      color: rgba(138, 146, 166, 1) !important;
      outline: none;
      border: none;

      &::placeholder {
        font-family: "Roboto-Regular";
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
      }

      @media screen and (max-width: $MaxWidthS) {
        padding: 4px 10px 4px 10px;
        font-size: 15px;
        &::placeholder {
          font-family: "Roboto-Regular";
          font-size: 15px;
        }
      }
    }

    > fieldset {
      outline: none;
      border-color: white;
    }
  }
}
