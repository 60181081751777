@import "../assets/styles/mixines";

.Wrapper {
  height: 100%;
  background: rgb(194 217 240 / 12%);
  .Container {
    min-height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    top: 55px;
    padding: 24px 24px;
    @media screen and (max-width: $MaxWidthL) {
      top: 95px;
    }
  }
}

.Linear {
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0;
  z-index: 9999999999999999999 !important;
}